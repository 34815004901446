import React, { useEffect, useState } from 'react';
import '../styles/index.css';

function Index() {

  return (
    <main>
      <h1>Currency by N-O</h1>    
    </main>
  );
}

export default Index;
